<template>
  <div class="home">
    <Sidebar msg="Welcome to Your Vue.js App" />
    <router-view />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
    watch: {
    '$route' (to) {
      document.title = to.meta.title || 'unibw Leichte Sprache'
    }
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  flex-direction: row;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
}

:root {
  --sidebar-font-size: 1.15rem;
  --primary-color: #ff6600;
  --primary-color-shade: hsl(24, 100%, 65%);
  --secondary-color: #666666;
  --secondary-color-shade: #979797;
  --fourth-color:rgb(103, 31, 197);
  --fifth-color: rgb(2, 218, 218);
  --fifth-color-shade: rgb(0, 204, 255);
  --sixth-color: rgb(164, 0, 185);
  --sixth-color-shade: rgb(209, 4, 236);
  --text-color: rgb(102, 102, 102);
  --background: rgb(240, 240, 240);
  --purple: #845ec2;
}

::selection {
  background: var(--primary-color);
  color: white;
}
</style>
