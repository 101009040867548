<template>
  <div id="sidebar">
    <img
      id="logo"
      src="https://cdn.josuakoehler.de/unibw/unibw2.svg"
    />
    <nav class="nav">
      <router-link
        :to="`/${item[0]}`"
        v-for="(item, i) in menu"
        :key="item"
        class="nav_item_wrapper"
      >
        <Button
          class="menu_item"
          :class="{ selected: current_selection === i }"
          @click="current_selection = i"
        >
          <span class="material-icons my_icon">{{ item[1] }}</span
          ><span class="menu_entry_text">{{ item[0] }}</span>
        </Button>
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        ["Korrektur", "article"],
        ["Wortzerlegung", "content_cut"],
        ["Schwere Worte", "feedback"],
        ["Synoynme", "published_with_changes"],
        ["Satzbau", "build"],
        ["Defenitionen", "book"],
      ],
      current_selection: 0,
    };
  },
};
</script>

<style>
#sidebar {
  width: 17vw;
  background-color: white;
  font-size: var(--sidebar-font-size);
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

#sidebar:after {
  content: "";
  background: var(--secondary-color);
  position: absolute;
  bottom: 10%;
  left: 100%;
  height: 80%;
  width: 1px;
}

.menu_entry_text{
  overflow: hidden;
}

.nav {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

#logo {
  padding: 20px;
  width: 80%;
  align-self: center;
}

.menu_item {
  text-align: left;
  background-color: #fff;
  padding: 10px;
  height: 3rem;
  outline: none;
  border: none;
  border-radius: 10px;
  font-size: var(--sidebar-font-size);
  transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, text-shadow 0.5s, transform 0.2s, background-position 0.3s ease-in-out;
  cursor: pointer;
  background-image: linear-gradient(to right, var(--primary-color), var(--primary-color-shade), #fff, #fff);
  background-size: 300%;
  background-position: right;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.menu_item:hover,
.selected {
  background-color: var(--primary-color);
  /* background: linear-gradient(to right, var(--primary-color), var(--primary-color-shade)); */
  box-shadow: 0 0.1px 0.4px rgba(0, 0, 0, 0.089),
    0 0.4px 1.3px rgba(0, 0, 0, 0.131), 0 2px 6px rgba(0, 0, 0, 0.22);
  color: white;
  text-shadow: 1px 1px 2px #424242;
  background-position: left;
}

.my_icon {
  font-size: calc(var(--sidebar-font-size) * 1.5);
  vertical-align: middle;
  margin-bottom: 0.24rem;
  margin-right: 1ch;
}

a {
  color: inherit;
  text-decoration: inherit;
    margin: 0.2rem 1rem;
    width: 90%;
}
.nav_item_wrapper {
  display: flex;
  flex-direction: column;
}


</style>