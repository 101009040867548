<template>
  <div id="korrektur">
    <h1>Korrektur</h1>
    <div id="main_content">
      <div class="text_split">
        <div
          class="text_input"
          contenteditable="true"
          placeholder="Text hier einfügen..."
          @input="(event) => onInput(event)"
        ></div>
        <div class="lower_content_wrapper">
          <div style="margin-top: 1rem" class="text_input text_output">
            <div class="item_wrapper">
              <span
                v-for="(val, key) in splitter_response"
                :key="key"
                :class="{
                  Splitted: val['splitting_pred'],
                  Definition: val['known_definition'],
                }"
                @click="word_clicked(val)"
              >
                {{ val["Word"] }}{{ ` ` }}</span
              >
            </div>
          </div>
          <div class="more_info text_input">
            <KorrekturInfoSplitting
              :current_clicked="current_clicked"
              v-if="active_more_info_item === 'Wortzerlegung'"
            />
            <KorrekturInfoDefinition
              :current_clicked="current_clicked"
              v-if="active_more_info_item === 'Definitionen'"
            />
          </div>
          <div class="nav_more_info">
            <div v-for="(k, v, i) in modules"
              :key="k">
            <div
              @click="active_more_info_item = v"
              :class="[
                `modul${i}`,
                'material-icons',
                'nav_item_more_info',
                active_more_info_item == v ? 'activated' : 'deactivated',
              ]"
            >
              {{ modul_icons[v] }}
            </div>
            </div>
          </div>
        </div>
      </div>
      <div id="options">
        <div><h3>Module:</h3></div>
        <div class="module">
          <div
            v-for="(k, v, i) in modules"
            :key="k"
            :class="[
              `modul_selector`,
              `modul${i}`,
              modules[v] ? 'activated' : 'deactivated',
            ]"
            @click="modules[v] = !modules[v]"
          >
            <span class="material-icons card_icon">{{ modul_icons[v] }}</span
            >{{ v }}
          </div>
        </div>
        <button id="submit" @click="send_data" v-if="display_send_button">
          <span class="material-icons" id="icon_settings">settings</span>Prüfen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import KorrekturInfoSplitting from "@/components/KorrekturInfoSplitting.vue";
import KorrekturInfoDefinition from "@/components/KorrekturInfoDefinition.vue";

export default {
  components: {
    KorrekturInfoSplitting,
    KorrekturInfoDefinition,
  },
  methods: {
    word_clicked(val) {
      let word = val["Word"];
      this.current_clicked["splittingDetect"] = val;
      fetch(`http://127.0.0.1:5000/subwords?word=${word}&max_count=10`)
        .then((response) => response.json())
        .then((data) => (this.current_clicked["splittingPossiblities"] = data))
        .catch((err) => console.log(err));
      fetch(`http://127.0.0.1:5000/GetDefinition?word=${word}`)
        .then((response) => response.json())
        .then((data) => (this.current_clicked["definition"] = data))
        .catch((err) => console.log(err));
    },
    onInput(event) {
      console.log(event.target);
      this.text = event.target.innerText;
      this.text_input = event.target;
      this.send_data();
    },
    async send_data() {
      var data = new FormData();
      data.append("text", this.text);
      const rawResponse = await fetch(
        "http://localhost:5000/SplitWordsFromText",
        {
          method: "POST",
          body: data,
        }
      );
      this.splitter_response = await rawResponse.json();
      console.log(this.splitter_response);
    },
  },
  data() {
    return {
      modules: {
        Wortzerlegung: true,
        "Schwere Worte": true,
        Synonyme: true,
        Definitionen: true,
        Zahlen: true,
      },
      modul_icons: {
        Wortzerlegung: "content_cut",
        "Schwere Worte": "feedback",
        Synonyme: "published_with_changes",
        Definitionen: "book",
        Zahlen: "repeat_one",

      },
      text: "",
      splitter_response: {},
      text_input: undefined,
      current_clicked: {},
      active_more_info_item: "Wortzerlegung",
      display_send_button: false,
    };
  },
};
</script>

<style scoped>
#korrektur {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  justify-content: start;
  max-height: 100vh;
}

h1 {
  font-weight: 400;
  text-align: left;
}

.text_split {
  display: flex;
  flex-direction: column;
  width: 80%;
}

#main_content {
  align-self: baseline;
  display: flex;
  padding: 1rem 0 1rem 0;
  flex: 1;
  width: 100%;
}
.text_input {
  padding: 10px;
  text-align: left;
  transition: all 0.5s;
  flex: 1;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 15px rgba(70, 70, 70, 0.12);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.text_input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-color);
}

.text_input:empty::before {
  content: attr(placeholder);
  color: #555;
}
.text_input:empty:focus::before {
  content: "";
}

#options {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#submit {
  margin-top: auto;
  padding: 1rem 2rem 1rem 2rem;
  width: fit-content;
  border-radius: 10px;
  border: none;
  align-self: center;
  transition: all 0.5s ease-in-out;
  font-size: var(--sidebar-font-size);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

#submit:hover {
  color: white;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 0 3px white, 0px 0px 0 6px var(--primary-color);
}

#submit:focus {
  outline: none;
}

#submit:hover span {
  animation: example 3s linear infinite;
}

@keyframes example {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

#icon_settings {
  font-size: calc(var(--sidebar-font-size) * 1.5);
  vertical-align: middle;
  margin-bottom: 0.24rem;
  margin-right: 0.6ch;
}

.module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modul_selector {
  margin-top: 1.5rem;

  width: 90%;
  color: white;
  display: flex;
  align-items: center;
  font-size: var(--sidebar-font-size);

  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}
.modul_selector.deactivated,
.nav_item_more_info.deactivated,
.modul_selector.activated:hover {
  transition: all 0.2s ease-in-out;
  background-position: right;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.5),
    10px 10px 20px rgba(70, 70, 70, 0.12);
}

.modul_selector.activated,
.nav_item_more_info.activated,
.modul_selector.deactivated:hover {
  transition: all 0.2s ease-in-out;
  background-position: left;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

.card_icon {
  color: white;
  padding: 0.5vw;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(32, 32, 32, 0.37);
  backdrop-filter: blur(5.5px);
  border-radius: 10px;
  height: calc(var(--sidebar-font-size) * 1.5);
  transition: transform 0.2s ease-in-out;
  margin: 0.4rem;
  vertical-align: bottom;
}

.modul0 {
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--primary-color-shade) 46%,
    var(--secondary-color-shade) 54%,
    var(--secondary-color) 300%
  );
  background-size: 300%;
}
.modul1 {
  background: linear-gradient(
    90deg,
    rgb(62, 52, 245) 0%,
    rgb(0, 156, 187) 46%,
    var(--secondary-color-shade) 54%,
    var(--secondary-color) 300%
  );
  background-size: 300%;
}
.modul2 {
  background: linear-gradient(
    90deg,
    rgb(29, 145, 0) 0%,
    rgb(37, 173, 44) 46%,
    var(--secondary-color-shade) 54%,
    var(--secondary-color) 300%
  );
  background-size: 300%;
}

.modul3 {
  background: linear-gradient(
    90deg,
    rgb(77, 0, 219) 0%,
    var(--fourth-color) 46%,
    var(--secondary-color-shade) 54%,
    var(--secondary-color) 300%
  );
  background-size: 300%;
}

.modul4 {
  background: linear-gradient(
    90deg,
    var(--fifth-color) 0%,
    var(--fifth-color-shade) 46%,
    var(--secondary-color-shade) 54%,
    var(--secondary-color) 300%
  );
  background-size: 300%;
}

.Splitted {
   background-image:linear-gradient(90deg, var(--primary-color), var(--primary-color-shade));
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}
.Definition{
  color: var(--fourth-color);
}
.Splitted.Definition{
 background-image:linear-gradient(90deg, var(--primary-color), var(--fourth-color));
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}

.lower_content_wrapper {
  flex: 1;
  max-height: 50%;
  height: 50%;
  display: flex;
  flex-direction: row;
}

.more_info {
  flex: 1;
  margin-left: 1rem;
  margin-top: 1rem;
}

.text_output {
  flex: 2;
}

.nav_more_info {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.nav_item_more_info {
  padding: 1rem;
  border-radius: 0 10px 10px 0;
  color: white;
  vertical-align: bottom;
  margin-top: 0.6vw;
  cursor: pointer;
}
</style>