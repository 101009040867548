<template>
  <div class="splitting_wrapper">
    <div class="splittingDetected" v-if="'splittingDetect' in current_clicked">
      <h2 class="card_header">
        {{ current_clicked["definition"]["query"] }}
      </h2>

    </div>
    <div class="definition" v-if="typeof current_clicked['definition'] !== 'undefined' &&'definition' in current_clicked['definition']">
        {{current_clicked["definition"]["definition"]}}
    </div>
    <div v-else class="definition">
        Es wurden leider keine Definitionen für {{current_clicked["definition"]["query"]}} gefunden.
    </div>
  </div>
</template>

<script>
export default {
  props: ["current_clicked"],
};
</script>

<style scoped> 
.splittingDetected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card_header {
  min-width: 100%;
}

.splitting_wrapper{
  max-height: 100%;
  overflow: auto;
}

.definition{
    margin-top: 1rem;
}
</style>