<template>
  <div class="splitting_wrapper">
    <div class="splittingDetected" v-if="'splittingDetect' in current_clicked">
      <h2 class="card_header">
        {{ current_clicked["splittingDetect"]["Word"] }}
      </h2>
      <span
        >RadomForrest:
        {{
          Math.round(current_clicked["splittingDetect"]["splitting_pred_prop"] * 10000) /
          100
        }}%</span
      >
      <span>In Liste: {{ current_clicked["splittingDetect"]["splitting_in_list"] }}</span>
      <span>Vorhersage: {{ current_clicked["splittingDetect"]["splitting_pred"] }}</span>
    </div>
    <div class="splittingPossibilities">
      <div
        class="single_possibility"
        v-for="i in current_clicked['splittingPossiblities']"
        :key="i"
      >
        <div class="zerlegung">
          <span v-for="(j, idx) in i['subwords']" :key="j">{{
            j[0] + (idx == i["subwords"].length - 1 ? " " : "-")
          }}</span>
          <span class="zerlegung_prop"
            ><span v-if="i['lexikon']" class="material-icons inline-icon">verified</span>{{ Math.round(i["covering_percentage"] * 10000) / 100 }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["current_clicked"],
};
</script>

<style scoped> 
.splittingDetected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card_header {
  min-width: 100%;
}

.splittingPossibilities {
  display: flex;
  flex-direction: column;
}
.zerlegung {
  color: var(--secondary-color);
  display: flex;
  padding: 0.3rem;
  transition: all 0.5s ease-in-out;
  border-radius: 8px;
}
.zerlegung:hover {
  color: white;
  background-color: var(--primary-color-shade);
}
.zerlegung:hover .zerlegung_prop {
  color: white;
}

.zerlegung span {
  white-space-collapsing: discard;
}
.zerlegung_prop {
  transition: all 0.5s ease-in-out;
  color: var(--primary-color-shade);
  margin-left: auto;
}

.inline-icon{
  vertical-align: middle;
}


.splitting_wrapper{
  max-height: 100%;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.splitting_wrapper::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
</style>